var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-left":"-30px","margin-right":"-30px"}},[_c('iframe',{staticStyle:{"width":"100%","height":"84vh","border":"none","border-radius":"8px"},attrs:{"src":_vm.$settings.pointSystemApiAddress +
        '#/dashboard?tenant=' +
        _vm.$settings.oem +
        '&token=' +
        _vm.$store.state.app.token +
        '&v=' +
        Date.now()}})])
}
var staticRenderFns = []

export { render, staticRenderFns }