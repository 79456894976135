<template>
  <div style="margin-left:-30px;margin-right:-30px;">
    <iframe
      style="width: 100%;height: 84vh;border:none;border-radius: 8px;"
      :src="
        $settings.pointSystemApiAddress +
          '#/dashboard?tenant=' +
          $settings.oem +
          '&token=' +
          $store.state.app.token +
          '&v=' +
          Date.now()
      "
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "PointsManagement"
};
</script>
